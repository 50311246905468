body {
  max-height: 100vh;
  overflow: hidden;
}

.logo {
  height: 30px;
  width: 30px;
  pointer-events: none;
}
.logo-404 {
  height: 63px;
  width: 63px;
  pointer-events: none;
}
.logo-footer {
  height: 20px;
  width: 20px;
  margin-right: 8px;
}
.link {
  text-decoration: none;
  color: inherit;
  width: 100%;
}
.activeLink {
  background-color: rgba(25, 118, 210, 0.08);
}
.logo-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hljs-module-access,
.hljs-constructor,
.hljs-property {
  color: red;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.grey {
  color: #6f7e8c;
}
textarea:focus {
  border-color: green;
}
.yellow {
  background-color: yellow;
}
.info {
  height: calc(100vh - 115px);
}
.interactive {
  padding: 18px 24px 40px 24px;
  height: calc(100vh - 115px);
}
.nav-desktop {
  height: calc(100vh - 160px);
  padding-top: 8px;
  padding-bottom: 40px;
}
.nav-mobile {
  height: calc(100vh - 178px);
  padding-bottom: 40px;
  padding-top: 8px;
}

.intro {
  height: calc(100vh - 115px);
}
.gif,
.vid {
  width: 100%;
  height: 100%;
}
